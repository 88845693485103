<template>
  <div>
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <b-row>
        <b-col cols="12" class="text-center mt-5" v-if="!loading && isOwner">
          <b-button variant="outline-secondary" exact :to="{name: 'billing'}">{{ $t('Back') }}</b-button>
          <b-button variant="primary" class="ml-4" @click="generateReport">{{ $t('Download Receipt PDF') }}</b-button>
        </b-col>
      </b-row>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="this.documentTitle"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-height="100%"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <!-- PDF Content Here -->
          <Invoice v-if="invoice && order && paymentTransaction" :invoiceData="invoice" :orderData="order" :paymentTransactionData="paymentTransaction" :documentTitle="documentTitle"></Invoice>
        </section>
      </vue-html2pdf>
      <div class="justify-content-center d-flex" v-if="!loading && isOwner">
        <Invoice v-if="invoice && order && paymentTransaction" :invoiceData="invoice" :orderData="order" :paymentTransactionData="paymentTransaction" :documentTitle="documentTitle"></Invoice>
      </div>
      <div v-else class="text-center py-4">
        <div class="search-notfound-image-box">
          <b-img  blank-color="#fff" class="" height="200" :src="require('../../assets/images/icon/yoga_search_not_found.png')"></b-img>
        </div>
        <b-button variant="outline-secondary" class="mt-3" exact :to="{name: 'billing'}">{{ $t('Back') }}</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import Api from '../../api/api'
import Invoice from '../../components/Invoice.vue'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'InvoiceDocument',
  title: 'Invoice Document',
  components: {
    Invoice,
    VueHtml2pdf
  },
  data () {
    return {
      loading: true,
      documentTitle: 'Receipt',
      invoice: null,
      order: null,
      paymentTransaction: null,
      isOwner: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    const invoiceId = this.$route.params.invoiceId
    this.getInvoice(invoiceId)
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress ($event) {
    },
    hasStartedGeneration () {
    },
    hasGenerated ($event) {
    },
    getInvoice (id) {
      Api.invoiceDetail({ invoiceId: id })
        .then((response) => {
          this.invoice = response.data
          this.getOrderDetail(this.invoice.order)
          this.getPaymentTransaction(this.invoice.id)
          this.loading = false
          if (this.userProfile.id !== this.invoice.user) {
            this.isOwner = false
          } else {
            this.isOwner = true
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getOrderDetail (orderId) {
      Api.orderDetail({ orderId: orderId })
        .then((response) => {
          this.order = response.data
        })
        .catch(() => {})
    },
    getPaymentTransaction (invoiceId) {
      Api.paymentTransaction({ invoice: invoiceId, status: 2 })
        .then((response) => {
          this.paymentTransaction = response.data.results[0]
        })
        .catch(() => {})
    }
  },
  destroyed () { }
}

</script>
